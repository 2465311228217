//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import ProductPrice from "@/components/pages/productCard/ProductContent/Components/ProductPrice/index";
import {mapGetters} from "vuex";

export default {
    name: "ProductInfo",
    components: {ProductPrice, ResponsiveImage},
    computed: {
        ...mapGetters({
            lastAdded: 'cart/getLastAdded',
        }),
        product() {
            return this.lastAdded.product;
        },
        chosenSize() {
            return this.product.sizes.find(i => i.id === this.lastAdded.tradeOfferId);
        },
        infoRows() {
            return this.product ? [
                {
                    label: this.$t('productCard.color'),
                    value: this.product.defaultColor
                },
                {
                    label: this.$t('productCard.size'),
                    value: this.chosenSize.title || ''
                },
                {
                    label: this.$t('productCard.count'),
                    value: 1
                },
            ] : []
        }
    },
    methods: {
        imageThumbnailUrl(image) {
            return ImageHelper.getMediaStructure(image.id, AppConfig.image.fileExtensions.productCard, this.product.displayName, this.product.displayName, ImageSizes.productAddedToCart, 80)
        },
    }
}
