import esEvent from './esEvent';
import generateGuid from '~/assets/js/helper/generateGuid';

function isNewCartStatus(cartKey, localStorageKeyName) {
    let oldCartStatusKey = window.localStorage.getItem(localStorageKeyName);
    let status = null;

    if (oldCartStatusKey && oldCartStatusKey === cartKey) {
        status = false;
    } else {
        status = true;
        window.localStorage.setItem(localStorageKeyName, cartKey);
    }

    return status;
}

export default function statusCartEvent(data, checkoutStatusState) {
    if(checkoutStatusState === 3){
        return;
    }

    const localStorageGuidHash = 'eSputnikGuid';
    const localStorageGuidKey = 'eSputnikGuidKey';
    const localStorageUserCurrentCartStatus = 'eSputnikUserCurrentCartStatus';
    const localStorageUserLastCartStatus = 'eSputnikUserLastCartStatus';

    if (typeof data === 'object') {
        sendEvent(data);
    } else {
        console.warn('Wrong input data', data);
    }

    function sendEvent(data) {
        const productItems = [];
        const cartStatusKeyArray = [];

        if (
            Array.isArray(data) &&
            data.length
        ) {
            data.forEach(item => {
                const productItemObj = {
                    productKey: item.tradeOfferId.toString(),
                    price: item?.product?.price?.sale.toString(),
                    quantity: item.quantity.toString(),
                };
                productItems.push(productItemObj);
                cartStatusKeyArray.push(`${ item.tradeOfferId }:${ item.quantity }`);
            });
        } else {
            cartStatusKeyArray.push('empty');
        }

        const isNewCartStatusVar = isNewCartStatus(cartStatusKeyArray.join(), localStorageGuidKey);

        const eventBody = generateEventBody(
            productItems,
            getGuid(isNewCartStatusVar, productItems)
        );


        window.localStorage.setItem(localStorageUserCurrentCartStatus, JSON.stringify(eventBody));

        esEvent('StatusCart', eventBody);
    }

    function generateEventBody(productItems, GUID) {
        return {
            'StatusCart': productItems,
            'GUID': GUID
        };
    }

    function saveLastCartStatus(productItems, GUID) {
        const currentUserState = window.localStorage.getItem(localStorageUserCurrentCartStatus);
        if (currentUserState) {
            window.localStorage.setItem(localStorageUserLastCartStatus, currentUserState);
        } else {
            const eventBody = generateEventBody(
                productItems,
                GUID,
            );

            window.localStorage.setItem(localStorageUserLastCartStatus, JSON.stringify(eventBody));
        }
    }

    function getGuid(isNewCartStatusVar, productItems) {
        let guidVar = null;

        if (isNewCartStatusVar) {
            guidVar = generateGuid();
            saveLastCartStatus(productItems, guidVar);
            window.localStorage.setItem(localStorageGuidHash, guidVar);
        } else {
            guidVar = window.localStorage.getItem(localStorageGuidHash);
            if (!guidVar) {
                console.error(`Should be window.localStorage.${ localStorageGuidHash }`);
            }
        }

        return guidVar;
    }
};

